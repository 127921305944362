<template>
  <div>
    <div class="row justify-content-end">
      <div class="col-auto">
        <button @click="refresh()" class="btn border">
          <i class="bi bi-check2-square fs-5"></i> Actualiser
        </button>
      </div>

      <div class="col-auto">
        <button
          @click="
            $router.push({
              name: 'checks-customers-edit',
              params: {
                reference: check.reference,
              },
            })
          "
          class="btn border"
        >
          <i class="bi bi-pencil-square fs-5"></i> Modifier
        </button>
      </div>
      <div class="col-auto">
        <button @click="refresh()" class="btn border">
          <i class="bi bi-printer fs-5"></i> Imprimer
        </button>
      </div>
      <div class="col-auto">
        <button @click="refresh()" class="btn border">
          <i class="bi bi-arrow-clockwise fs-5"></i> Actualiser
        </button>
      </div>

      <div class="col-auto" v-if="check.status != 'paid'">
        <button
          @click="refresh()"
          class="btn border border-success text-success"
        >
          <i class="bi bi-arrow-clockwise fs-5"></i> Payé
        </button>
      </div>

      <div class="col-auto" v-if="check.status != 'rejected'">
        <button
          @click="checkUnpaid(check)"
          class="btn border border-danger text-danger"
        >
          <i class="bi bi-arrow-clockwise fs-5"></i> Impayé
        </button>
      </div>
    </div>
    <br />
    <div class="row align-items-stretch">
      <div class="col-4">
        <div class="row justify-content-between">
          <div class="col">Client</div>
          <div class="col fw-bold">
            <span v-if="check.customer">
              {{ check.customer.fullName }}
            </span>
          </div>
        </div>

        <div class="row justify-content-between">
          <div class="col">Nº Telephone</div>
          <div class="col fw-bold">
            <span v-if="check.customer">
              {{ check.customer.phone }}
            </span>
          </div>
        </div>

        <div class="row justify-content-between">
          <div class="col">Societie</div>
          <div class="col fw-bold">
            <span v-if="check.customer">
              {{ check.customer.society }}
            </span>
          </div>
        </div>
      </div>

      <!--  -->
      <div class="col-4 justify-content-between border-start border-danger">
        <div class="row">
          <div class="col">État</div>
          <div class="col fw-bold">
            {{ check.status }}
          </div>
        </div>

        <div class="row">
          <div class="col">Remarque</div>
          <div class="col fw-bold">
            {{ check.remark }}
          </div>
        </div>
      </div>

      <!--  -->
      <div class="col-4 justify-content-between border-start border-danger">
        <div class="row">
          <div class="col">Montant Total</div>
          <div class="col fw-bold">{{ check.amount }} DH</div>
        </div>

        <div class="row">
          <div class="col">Nº Cheque</div>
          <div class="col fw-bold">
            {{ check.checkNumber }}
          </div>
        </div>

        <div class="row">
          <div class="col">Date</div>
          <div class="col fw-bold">
            {{ check.date }}
          </div>
        </div>

        <div class="row">
          <div class="col">Échéance</div>
          <div class="col fw-bold">0</div>
        </div>
      </div>
    </div>
    <br />
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {};
  },

  mounted() {
    this.$store.dispatch("check/show", this.$route.params.reference);
  },
  computed: {
    ...mapGetters("check", {
      check: "getCheck",
    }),
  },
  methods: {
    async refresh() {
      await this.$store.dispatch("check/show", this.$route.params.reference);
    },

    async checkUnpaid(data) {
      await this.$store.dispatch("check/unpaid", data);
    },

    async checkPaid(data) {
      await this.$store.dispatch("check/paid", data);
    },
  },
};
</script>
